


export const items = [
    '/assets/images/promotions/langding-pages/IMG3.jpg',
    '/assets/images/promotions/langding-pages/IMG4.jpg',
    '/assets/images/promotions/langding-pages/IMG5.jpg',
    '/assets/images/promotions/langding-pages/IMG6.jpg',
    '/assets/images/promotions/langding-pages/IMG7.jpg',
    '/assets/images/promotions/langding-pages/IMG8.jpg',
    '/assets/images/promotions/langding-pages/IMG14.jpg',
    '/assets/images/promotions/langding-pages/IMG15.jpg',
    '/assets/images/promotions/langding-pages/IMG16.jpg',
    '/assets/images/promotions/langding-pages/IMG17.jpg',
    '/assets/images/promotions/langding-pages/IMG18.jpg',
    '/assets/images/promotions/langding-pages/IMG19.jpg',
]