/** Events constants */
export const EVENT_ACTION_TYPES = {
  EVENT_LIST_REQUEST: "EVENT_LIST_REQUEST",
  EVENT_LIST_SUCCESS: "EVENT_LIST_SUCCESS",
  EVENT_LIST_FAIL: "EVENT_LIST_FAIL",
};

export const EVENT_LIST_SINGLE_TYPES = {
  EVENT_LIST_SINGLE_REQUEST: "EVENT_LIST_SINGLE_REQUEST",
  EVENT_LIST_SINGLE_SUCCESS: "EVENT_LIST_SINGLE_SUCCESS",
  EVENT_LIST_SINGLE_FAIL: "EVENT_LIST_SINGLE_FAIL",
};

export const EVENT_ACTION_SUBMIT_TYPES = {
  EVENT_LIST_SUBMIT_REQUEST: "EVENT_LIST_SUBMIT_REQUEST",
  EVENT_LIST_SUBMIT_SUCCESS: "EVENT_LIST_SUBMIT_SUCCESS",
  EVENT_LIST_SUBMIT_FAIL: "EVENT_LIST_SUBMIT_FAIL",
};

export const EVENT_ACTION_DELETE_TYPES = {
  EVENT_LIST_DELETE_REQUEST: "EVENT_LIST_DELETE_REQUEST",
  EVENT_LIST_DELETE_SUCCESS: "EVENT_LIST_DELETE_SUCCESS",
  EVENT_LIST_DELETE_FAIL: "EVENT_LIST_DELETE_FAIL",
};
export const EVENT_ACTION_UPDATE_TYPES = {
  EVENT_LIST_UPDATE_REQUEST: "EVENT_LIST_UPDATE_REQUEST",
  EVENT_LIST_UPDATE_SUCCESS: "EVENT_LIST_UPDATE_SUCCESS",
  EVENT_LIST_UPDATE_FAIL: "EVENT_LIST_UPDATE_FAIL",
  EVENT_LIST_UPDATE_RESET: "EVENT_LIST_UPDATE_RESET",
};
export const EVENT_INVITE_LIST_COUNT_ACTION_TYPES = {
  EVENT_INVITE_LIST_COUNT_REQUEST: "EVENT_INVITE_LIST_COUNT_REQUEST",
  EVENT_INVITE_LIST_COUNT_SUCCESS: "EVENT_INVITE_LIST_COUNT_SUCCESS",
  EVENT_INVITE_LIST_COUNT_FAIL: "EVENT_INVITE_LIST_COUNT_FAIL",
};
/** ---------Events END--------- */

/** Event Invite constants */
export const EVENT_INVITE_ACTION_TYPES = {
  EVENT_INVITE_LIST_REQUEST: "EVENT_INVITE_LIST_REQUEST",
  EVENT_INVITE_LIST_SUCCESS: "EVENT_INVITE_LIST_SUCCESS",
  EVENT_INVITE_LIST_FAIL: "EVENT_INVITE_LIST_FAIL",
};

export const EVENT_INVITE_DELETE_ACTION_TYPES = {
  EVENT_INVITE_DELETE_REQUEST: "EVENT_INVITE_DELETE_REQUEST",
  EVENT_INVITE_DELETE_SUCCESS: "EVENT_INVITE_DELETE_SUCCESS",
  EVENT_INVITE_DELETE_FAIL: "EVENT_INVITE_DELETE_FAIL",
};

export const EVENT_INVITES_ACTION_TYPES = {
  EVENT_INVITES_LIST_REQUEST: "EVENT_INVITES_LIST_REQUEST",
  EVENT_INVITES_LIST_SUCCESS: "EVENT_INVITES_LIST_SUCCESS",
  EVENT_INVITES_LIST_FAIL: "EVENT_INVITES_LIST_FAIL",
};
export const SUBMIT_INVITE_EVENT_ACTION_TYPES = {
  SUBMIT_INVITE_EVENT_REQUEST: "SUBMIT_INVITE_EVENT_REQUEST",
  SUBMIT_INVITE_EVENT_SUCCESS: "SUBMIT_INVITE_EVENT_SUCCESS",
  SUBMIT_INVITE_EVENT_FAIL: "SUBMIT_INVITE_EVENT_FAIL",
};

/** ---------Event Invite END--------- */

/** User constants */
export const USER_LOGIN_ACTION_TYPES = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
  USER_LOGOUT: "USER_LOGOUT ",
};

export const USER_LIST_ACTION_TYPES = {
  USER_LIST_REQUEST: "USER_LIST_REQUEST",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAIL: "USER_LIST_FAIL",
};

export const USER_DELETE_ACTION_TYPES = {
  USER_DELETE_REQUEST: "USER_DELETE_REQUEST",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_FAIL: "USER_DELETE_FAIL",
};

export const USER_ADD_ACTION_TYPES = {
  USER_ADD_REQUEST: "USER_ADD_REQUEST",
  USER_ADD_SUCCESS: "USER_ADD_SUCCESS",
  USER_ADD_FAIL: "USER_ADD_FAIL",
  USER_ADD_RESET: "USER_ADD_RESET",
};

/** ---------User Invite END--------- */

/** Contact constants */
export const CONTACT_ACTION_TYPES = {
  CONTACT_ACTION_TYPES_REQUEST: "CONTACT_ACTION_TYPES_REQUEST",
  CONTACT_ACTION_TYPES_SUCCESS: "CONTACT_ACTION_TYPES_SUCCESS",
  CONTACT_ACTION_TYPES_FAIL: "CONTACT_ACTION_TYPES_FAIL",
};
/** ---------Contact END--------- */

/** Inquiry constants */
export const INQUIRY_ACTION_TYPES = {
  INQUIRY_ACTION_TYPES_REQUEST: "INQUIRY_ACTION_TYPES_REQUEST",
  INQUIRY_ACTION_TYPES_SUCCESS: "INQUIRY_ACTION_TYPES_SUCCESS",
  INQUIRY_ACTION_TYPES_FAIL: "INQUIRY_ACTION_TYPES_FAIL",

  INQUIRY_ACTION_SUBMIT_TYPES_REQUEST: "INQUIRY_ACTION_SUBMIT_TYPES_REQUEST",
  INQUIRY_ACTION_SUBMIT_TYPES_SUCCESS: "INQUIRY_ACTION_SUBMIT_TYPES_SUCCESS",
  INQUIRY_ACTION_SUBMIT_TYPES_FAIL: "INQUIRY_ACTION_SUBMIT_TYPES_FAIL",
};
/** ---------Inquiry END--------- */

/** Agents constants */
export const AGENT_ACTION_TYPES = {
  AGENT_ACTION_GET_REQUEST: "AGENT_ACTION_GET_REQUEST",
  AGENT_ACTION_GET_SUCCESS: "AGENT_ACTION_GET_SUCCESS",
  AGENT_ACTION_GET_FAIL: "AGENT_ACTION_GET_FAIL",
};
export const SINGLE_AGENT_ACTION_TYPES = {
  SINGLE_AGENT_ACTION_GET_REQUEST: "SINGLE_AGENT_ACTION_GET_REQUEST",
  SINGLE_AGENT_ACTION_GET_SUCCESS: "SINGLE_AGENT_ACTION_GET_SUCCESS",
  SINGLE_AGENT_ACTION_GET_FAIL: "SINGLE_AGENT_ACTION_GET_FAIL",
};
export const ADD_AGENT_ACTION_TYPES = {
  ADD_AGENT_ACTION_REQUEST: "ADD_AGENT_ACTION_REQUEST",
  ADD_AGENT_ACTION_SUCCESS: "ADD_AGENT_ACTION_SUCCESS",
  ADD_AGENT_ACTION_FAIL: "ADD_AGENT_ACTION_FAIL",
  ADD_AGENT_ACTION_RESET: "ADD_AGENT_ACTION_RESET",
};
/** ---------Achievements constants--------- */
export const ACHIEVEMENT_ACTION_TYPES = {
  ACHIEVEMENT_ACTION_GET_REQUEST: "ACHIEVEMENT_ACTION_GET_REQUEST",
  ACHIEVEMENT_ACTION_GET_SUCCESS: "ACHIEVEMENT_ACTION_GET_SUCCESS",
  ACHIEVEMENT_ACTION_GET_FAIL: "ACHIEVEMENT_ACTION_GET_FAIL",
};

export const REWARD_ACTION_TYPES = {
  REWARD_ACTION_GET_REQUEST: "REWARD_ACTION_GET_REQUEST",
  REWARD_ACTION_GET_SUCCESS: "REWARD_ACTION_GET_SUCCESS",
  REWARD_ACTION_GET_FAIL: "REWARD_ACTION_GET_FAIL",
};