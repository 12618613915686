export const components = {
  POSITION_AGENT: [
    'Appointments',
    'AgentAppointments',
    'AppointmentInformation',
    'ScheduleAppointment',
    'Calendar',
    'Contacts',
    'Settings',
    'Blogs',
    'ViewBlog',
    'BlogForm',
    'Webinars',
    'TrialSubscription',
    'CFSWebinars',
    'ActivatedWebinars',
    'RequestedWebinars',
    'AdminWebinars',
    'DynamicWebinarInformation',
    'WebinarSingle',
    'LandingPage',
    'LandingPageInfo',
    'Users',
    'Licensing',
    'ContractForm',
    'EmailMarketing',
    'AgentSubscribers',
    'Merchandises',
    'MerchandiseForm',
    'ShareableEmails',
    'RewardsHistory',
    'RSVPLanding',
    'TrialSubscription',
  ],
  POSITION_MASTER_ADMIN: [
    'Appointments',
    'AgentAppointments',
    'AppointmentInformation',
    'ScheduleAppointment',
    'Calendar',
    'Contacts',
    'Settings',
    'Blogs',
    'ViewBlog',
    'BlogForm',
    'Webinars',
    'TrialSubscription',
    'CFSWebinars',
    'WebinarForm',
    'ActivatedWebinars',
    'RequestedWebinars',
    'AdminWebinars',
    'DynamicWebinarInformation',
    'WebinarSingle',
    'LandingPage',
    'LandingPageInfo',
    'Users',
    'Licensing',
    'ContractForm',
    'EmailMarketing',
    'AgentSubscribers',
    'Merchandises',
    'MerchandiseForm',
    'ShareableEmails',
    'RewardsHistory',
    'RSVPLanding',
    'Trial Subscription',
  ],
  POSITION_SUBSCRIBER: [
    'Dashboard',
    'Profile',
    'Subscribers',
    'Merchandises',
    'ShareableEmails',
    'Rewards History',
  ],
}
