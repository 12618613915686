// FAQs Array
export const faqs = [
  {
    header: "What is Finance ? 1",
    content:
      " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo molestias praesentium animi! Explicabo culpa nostrum unde voluptatum molestiae laudantium omnis.",
  },

  {
    header: "What is Finance ? 2",
    content:
      " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo molestias praesentium animi! Explicabo culpa nostrum unde voluptatum molestiae laudantium omnis.",
  },

  {
    header: "What is Finance ? 3",
    content:
      " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo molestias praesentium animi! Explicabo culpa nostrum unde voluptatum molestiae laudantium omnis.",
  },

  {
    header: "What is Finance ? 4",
    content:
      " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo molestias praesentium animi! Explicabo culpa nostrum unde voluptatum molestiae laudantium omnis.",
  },
];
